import { ModalProgrammatic as Modal } from 'buefy';
import Vue from 'vue';
import HrbrAgreementEditorModal from '../components/AgreementEditor/HrbrAgreementEditorModal.vue';
import * as agreementEditorConfig from '../config/agreement-editor';


/**
 * This plugin is needed to open a modal from any component, even from old code.
 */
function openAgreementEditorModal(options = {}) {
  const { props = {} } = options;

  const parent = props.parent !== undefined ? props.parent : this;

  const modal = Modal.open({
    component: HrbrAgreementEditorModal,
    parent,
    fullScreen: props.fullScreen || false,
    hasModalCard: true,
    canCancel:  ['outside'],
    customClass: 'hrbr-agreement-editor-modal',
    props,
    events: {
      'update-active-agreementid': (agreementId) => {
        if (props && props?.agreementId) {
          props.agreementId = agreementId;
        }
      },
    },
    onCancel: () => {
      if (props.isEditingApprovalFlowLink) {
        window.parent.postMessage('close', '*');
      }
    }
  });

  // class is used instead 'is-clipped' to prevent page scrolling
  document.documentElement.classList.add('hrbr-agreement-editor-modal--opened');

  return modal;
}

export default {
  install(Vue) {
    Vue.prototype.$openAgreementEditorModal = openAgreementEditorModal;
    Vue.prototype.$agreementEditorConfig = agreementEditorConfig;
  },
};
